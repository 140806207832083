import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../hooks';
import { ApiResponse } from '../types';
import { Lead } from '../types/lead';
import { useLead } from './leads.context';

type CreateLeadRequest = Partial<Lead>;
type UpdateLeadRequest = Partial<Lead>;

export const useCreateLead = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { updateLead } = useLead();

  return useMutation(
    async ({ data }: { data: CreateLeadRequest }) => {
      const response = await axios.post<ApiResponse<Lead>>(`/leads`, data);

      return response.data.data;
    },
    {
      onSuccess: data => {
        updateLead(data);
        queryClient.setQueryData('lead', () => data);
      },
      onError: () => {
        //
      },
    },
  );
};

export const useUpdateLead = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { lead, updateLead } = useLead();

  return useMutation(
    (data: UpdateLeadRequest) =>
      axios.put<ApiResponse<Lead>>(`/leads/${lead.id}`, data),
    {
      onSuccess: data => {
        queryClient.setQueryData('lead', () => data.data);
        updateLead(data.data.data);
      },
      onError: () => {
        //
      },
    },
  );
};
