import { useNavigate } from 'react-router-dom';
import { routeConfig } from '../routes/config';
import useSearchQuery, { SearchParam } from './use-search-query';

export default function useRouteChange(basePath = routeConfig.download.path) {
  const navigate = useNavigate();
  const { searchParams, setSearchParams } = useSearchQuery();

  const updateRoute = (path: string, searchParam?: SearchParam) => {
    if (searchParam) {
      setSearchParams(searchParam);
    }

    navigate(`/${basePath}/${path}?${searchParams}`);
  };

  return { navigate: updateRoute };
}
