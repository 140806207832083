import { Tabs, TabsProps } from 'antd';
import { useEffect } from 'react';
import { Container } from '../../components';
import { ProductType } from '../../config';
import { useLead } from '../../services/leads.context';
import { useGetSite } from '../../services/site.api';
import styles from './download-products.module.css';
import ProductLinks from './product-links';

export default function DownloadProducts() {
  const { lead, updateLead } = useLead();
  const { data, isLoading } = useGetSite();
  const isOroposAvailable =
    lead.product === ProductType.OROPOS || lead.product === ProductType.BOTH;

  useEffect(() => {
    updateLead({
      step: 4,
    });
  }, []);

  const items: TabsProps['items'] = [
    {
      key: '2',
      label: `FLOREANT POS`,
      children: (
        <ProductLinks isLoading={isLoading} links={data?.floreant ?? []} />
      ),
    },
  ];

  if (isOroposAvailable && items) {
    items.unshift({
      key: '1',
      label: `OROPOS`,
      children: (
        <ProductLinks isLoading={isLoading} links={data?.oropos ?? []} />
      ),
    });
  }

  return (
    <Container>
      {isOroposAvailable && (
        <div className={styles.header}>
          <h2 className={styles.heading}>
            To Activate ORO POS demo, use <span>PIN {lead.pin}</span>
          </h2>
          <p className={styles.subheading}>
            For this email: <b>{lead.email}</b>
          </p>
        </div>
      )}

      <div className={styles.content}>
        <Tabs items={items} />
      </div>
    </Container>
  );
}
