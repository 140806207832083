import { useQuery } from 'react-query';

import { useAxios } from '../hooks';

interface AllowedCountry {
  isAllowed: true;
  isVpn: false;
}

export const useGetFromAllowedCountries = () => {
  const axios = useAxios();

  return useQuery<AllowedCountry, Error>(
    'allowed-countries',
    async () => {
      const { data } = await axios.get(`/restricted/is-from-allowed-countries`);

      return data;
    },
    {
      staleTime: 60 * 1000,
    },
  );
};

export const useGetRestricted = () => {
  const axios = useAxios();

  return useQuery<AllowedCountry, Error>(
    'restricted',
    async () => {
      const { data } = await axios.get(`/restricted`);

      return data;
    },
    {
      staleTime: 60 * 1000,
    },
  );
};
