import { QueryClient, QueryClientProvider } from 'react-query';
import { Layout } from './components';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';
import './app.module.css';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
