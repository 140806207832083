import logo from '../../assets/images/orocube-logo.png';
import { menus } from '../../config';
import Container from '../container';
import styles from './header.module.css';

export default function Header() {
  return (
    <div className={styles.header}>
      <Container>
        <div className={styles.wrapper}>
          <img src={logo} alt="Orocube LOGO" />
          <nav>
            {menus.map(item => (
              <a className={styles.link} key={item.label} href={item.link}>
                {item.label}
              </a>
            ))}
          </nav>
        </div>
      </Container>
    </div>
  );
}
