import { Product } from '../types/product';

export enum ProductType {
  OROPOS = 'oropos',
  FLOREANT = 'floreant',
  BOTH = 'both',
}

export const products: Product[] = [
  {
    id: ProductType.OROPOS,
    title: 'oropos',
    content: 'Full featured Point of Sale with Support and Upgrade',
    features: [
      'Store open & end of Day report',
      'Drawer & Staff bank Report',
      '58mm, 76mm, 80mm Paper ',
      'Receipt Designer',
      'ESC/POS & Jasper Printing',
      'Multiple Tax per Item',
    ],
  },
  {
    id: ProductType.FLOREANT,
    title: 'floreant pos',
    content: 'Basic Open Source Point of Sale',
    features: [
      'EoD Store report',
      'Drawer Report',
      '80mm Paper ',
      'Fixed Printer layout',
      'Jasper Printer',
      'Single Tax per Item',
    ],
  },
  {
    id: ProductType.BOTH,
    title: 'both',
    content: 'Full featured & Basic Open Source Point of Sale',
  },
];
