import { Row, Col } from 'antd';
import { businessTypes } from '../../config';
import { useRouteChange } from '../../hooks';
import { routeConfig } from '../../routes/config';
import { useLead } from '../../services/leads.context';
import {
  useGetFromAllowedCountries,
  useGetRestricted,
} from '../../services/restricted.api';
import BusinessTypeSkeleton from './business-type-skeleton';
import styles from './business-type.module.css';

export default function Download() {
  const getRestricted = useGetRestricted();
  const getFromAllowedCountries = useGetFromAllowedCountries();
  const { updateLead } = useLead();
  const { navigate } = useRouteChange();

  const handleBusinessTypeClick = (businessType: string) => {
    updateLead({
      businessType: businessType,
      step: 1,
    });

    const searchParams = { key: 'businessType', value: businessType };

    navigate(routeConfig.download.children.products.path, searchParams);
  };

  return (
    <>
      {getRestricted.isLoading || getFromAllowedCountries.isLoading ? (
        <BusinessTypeSkeleton />
      ) : (
        <Row gutter={[64, 64]}>
          {businessTypes.map(item => (
            <Col span={6} key={item.label}>
              <div
                className={styles.card}
                onClick={() => handleBusinessTypeClick(item.label)}
              >
                <img src={item.image} alt={item.label} />
                <h1 className={styles.label}>{item.label}</h1>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
