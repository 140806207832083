import { useQuery } from 'react-query';
import { useAxios } from '../hooks';
import { ApiResponse } from '../types';

export type Link = {
  label: string;
  url: string;
};

type Section = {
  links: Link[];
};

interface Site {
  pages?: {
    sections: Section[];
  }[];
}

type GetSiteResponse = {
  oropos: Link[];
  floreant: Link[];
};

export const useGetSite = () => {
  const axios = useAxios();

  return useQuery<GetSiteResponse, Error>('site', async () => {
    const { data } = await axios.get<ApiResponse<Site>>(`/sites/orocube`);

    const page = data.data?.pages?.[2];
    const oropos = page?.sections?.[5].links;
    const floreant = page?.sections?.[6].links;

    return {
      oropos,
      floreant,
    } as GetSiteResponse;
  });
};
