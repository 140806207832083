import { Space, Row, Col } from 'antd';
import { products, ProductType } from '../../config';
import { useRouteChange } from '../../hooks';
import { routeConfig } from '../../routes/config';
import { useLead } from '../../services/leads.context';
import Product from './product';

export default function Download() {
  const { updateLead } = useLead();
  const { navigate } = useRouteChange();

  const handleProductSelect = (productId: ProductType) => {
    updateLead({
      product: productId,
      step: 2,
    });

    const searchParams = { key: 'product', value: productId };

    navigate(routeConfig.download.children.basicInfo.path, searchParams);
  };

  return (
    <Space>
      <Row gutter={[64, 64]}>
        {products.map(product => (
          <Col span={8} key={product.id}>
            <Product onSelect={handleProductSelect} product={product} />
          </Col>
        ))}
      </Row>
    </Space>
  );
}
