import { Progress, Typography } from 'antd';
import {
  useGetFromAllowedCountries,
  useGetRestricted,
} from '../../services/restricted.api';
import { Outlet } from 'react-router-dom';
import { LeadProvider } from '../../services/leads.context';
import styles from './download.screens.module.css';
import { useEffect } from 'react';
import { routeConfig } from '../../routes/config';
import { useRouteChange } from '../../hooks';
import ProgressBar from './progress-bar';

export default function Download() {
  const getRestricted = useGetRestricted();
  const getFromAllowedCountries = useGetFromAllowedCountries();
  const { navigate } = useRouteChange();

  useEffect(() => {
    if (getRestricted.isSuccess && getFromAllowedCountries.isSuccess) {
      navigate(routeConfig.download.children.businessType.path);
    }
  }, [getRestricted.isSuccess, getFromAllowedCountries.isSuccess]);

  return (
    <div className={styles.wrapper}>
      <LeadProvider>
        <div className={styles.header}>
          <Typography.Title>Download Products</Typography.Title>
          <Typography.Paragraph>No Credit Card Required</Typography.Paragraph>

          <div className={styles.progress}>
            <ProgressBar />
          </div>
        </div>
        <Outlet />
      </LeadProvider>
    </div>
  );
}
