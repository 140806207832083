import React, { createContext, useState } from 'react';
import { Lead, LeadModel } from '../types/lead';

export type LeadContext = {
  lead: LeadModel;
  updateLead: (lead: LeadModel) => void;
};

const defaultState = {
  lead: {},
  updateLead: () => {},
};

// eslint-disable-next-line @typescript-eslint/ban-types
const LeadContext = createContext<LeadContext>(defaultState);

function LeadProvider({ children }: { children: React.ReactNode }) {
  const [lead, setLead] = useState<LeadModel>(defaultState.lead);

  const updateLead = (leadData: Partial<Lead> = {}) => {
    if (lead) {
      setLead({
        ...lead,
        ...leadData,
      });
    } else {
      setLead(leadData);
    }
  };

  const value = { lead, updateLead };

  return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>;
}

function useLead() {
  const context = React.useContext(LeadContext);
  if (context === undefined) {
    throw new Error('useLead must be used within a LeadProvider');
  }

  return context as LeadContext;
}

export { LeadProvider, useLead };
