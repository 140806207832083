import quickService from '../assets/images/quick-service.svg';
import bars from '../assets/images/bars.svg';
import cafe from '../assets/images/cafe.svg';
import candyShop from '../assets/images/candy-shops.svg';
import foodTruck from '../assets/images/food-truck.svg';
import pizzeria from '../assets/images/pizzeria.svg';
import restaurants from '../assets/images/restaurants.svg';
import retail from '../assets/images/retail.svg';

export const apiEndpoint = process.env.REACT_APP_API;
export * from './products';

export const menus = [
  {
    label: 'Home',
    link: '/home',
  },
  {
    label: 'Pricing',
    link: 'https://shop.orocube.com/',
  },
  {
    label: 'Hardware',
    link: 'https://pos.orocube.com/hardware/',
  },
  {
    label: 'Support',
    link: 'https://zd.orocube.com/',
  },
];

export const footerMenusOne = [
  {
    label: 'Home',
    link: '/home',
  },
  {
    label: 'Pricing',
    link: 'https://shop.orocube.com/',
  },
  {
    label: 'Download',
    link: 'https://forms.orocube.com/download',
  },
  {
    label: 'Hardware',
    link: 'https://pos.orocube.com/hardware/',
  },
  {
    label: 'Support',
    link: 'https://zd.orocube.com/',
  },
];

export const businessTypes = [
  {
    label: 'Quick Service',
    image: quickService,
  },
  {
    label: 'Cafe',
    image: cafe,
  },
  {
    label: 'Restaurants',
    image: restaurants,
  },
  {
    label: 'Pizzeria',
    image: pizzeria,
  },
  {
    label: 'Retail',
    image: retail,
  },
  {
    label: 'Bar',
    image: bars,
  },
  {
    label: 'Food Truck',
    image: foodTruck,
  },
  {
    label: 'Candy Shops',
    image: candyShop,
  },
];
