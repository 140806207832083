import { createBrowserRouter } from 'react-router-dom';
import {
  VerifyScreen,
  DownloadScreen,
  BusinessTypeScreen,
  BasicInfoScreen,
  ProductScreen,
  DownloadProductsScreen,
} from '../screens';
import { routeConfig } from './config';

export const router = createBrowserRouter([
  {
    path: routeConfig.download.path,
    element: <DownloadScreen />,
    children: [
      {
        path: routeConfig.download.children.businessType.path,
        element: <BusinessTypeScreen />,
      },
      {
        path: routeConfig.download.children.products.path,
        element: <ProductScreen />,
      },
      {
        path: routeConfig.download.children.basicInfo.path,
        element: <BasicInfoScreen />,
      },
      {
        path: routeConfig.download.children.verify.path,
        element: <VerifyScreen />,
      },
      {
        path: routeConfig.download.children.downloadProducts.path,
        element: <DownloadProductsScreen />,
      },
    ],
  },
]);
