import { Col, Row, Skeleton } from 'antd';
import { businessTypes } from '../../config';
import styles from './business-type.module.css';

export default function BusinessTypeSkeleton() {
  return (
    <Row gutter={[64, 64]}>
      {businessTypes.map(item => (
        <Col span={6} key={item.label}>
          <div className={styles.card}>
            <Skeleton.Image active />
            <Skeleton.Input active size="small" />
          </div>
        </Col>
      ))}
    </Row>
  );
}
