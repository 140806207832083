import { Button, Col, Row, Skeleton } from 'antd';
import { Link } from '../../services/site.api';
import styles from './download-products.module.css';

type Props = {
  links: Link[];
  isLoading: boolean;
};

export default function ProductLinks({ links = [], isLoading }: Props) {
  return (
    <div>
      <div className={styles.productHeader}>
        <div className={[styles.divider, styles.productItem].join(' ')}>
          Feature
        </div>
        <div className={styles.productItem}>Download Links</div>
      </div>

      {isLoading
        ? [1, 2, 3, 4, 5.6].map(item => (
            <div key={item} className={styles.productContent}>
              <div className={styles.productItem}>
                <Skeleton.Input active size="small" />
              </div>
              <div className={styles.productItem}>
                <Skeleton.Button active size="small" />
              </div>
            </div>
          ))
        : links.map(link => (
            <div key={link.label} className={styles.productContent}>
              <div className={styles.productItem}>{link.label}</div>
              <div className={styles.productItem}>
                <Button type="primary" ghost href={link.url}>
                  Download
                </Button>
              </div>
            </div>
          ))}
    </div>
  );
}
