import { useLocation, useSearchParams } from 'react-router-dom';

export type SearchParam = {
  key: string;
  value: string;
};

export default function useSearchQuery() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const setSearchParams = (param: SearchParam) => {
    searchParams.set(param.key.toLowerCase(), param.value);
  };

  return {
    searchParams,
    setSearchParams,
  };
}
