import styles from './footer.module.css';
import { Container } from '../';
import logoImage from '../../assets/images/orocube-logo.png';
import { footerMenusOne } from '../../config';
import { Button } from 'antd';

export default function Footer() {
  return (
    <div>
      <Container>
        <div className={styles.container}>
          <div>
            <img src={logoImage} alt="Orocube.com" />
            <p className={styles.address}>
              1509 Johnson Ferry Rd T6, Marietta GA 30062, USA
            </p>
          </div>

          <div>
            <h2 className={styles.menuHeading}>Quick Links</h2>
            <div className={styles.menus}>
              <ul className={styles.list}>
                {footerMenusOne.map(menu => (
                  <li key={menu.label}>
                    <Button type="link" href={menu.link}>
                      {menu.label}
                    </Button>
                  </li>
                ))}
              </ul>
              <ul className={styles.list}>
                {footerMenusOne.map(menu => (
                  <li key={menu.label}>
                    <Button type="link" href={menu.link}>
                      {menu.label}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>

      <div className={styles.copyright}>
        <p>Copyright © {new Date().getFullYear()} OROCUBE LLC</p>
      </div>
    </div>
  );
}
