import { Input, Select, Typography } from 'antd';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { countries } from '../../config/countries';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type Props = {
  onCountryChange: (dialCode: string) => void;

  onPhoneChange: (phone: string) => void;
  defaultCountryName?: string;
};

const schema = yup.object({
  dialCode: yup.string().required('Please enter your dial code'),
  phone: yup
    .number()
    .typeError('Please enter only number')
    .integer('Please enter only number')
    .required('Please enter your phone'),
});

export default function Phone({
  onCountryChange,
  onPhoneChange,
  defaultCountryName = '',
}: Props) {
  const {
    watch,
    trigger,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      dialCode: defaultCountryName,
      phone: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'phone' && value.phone) {
        trigger('phone');
        onPhoneChange(value.phone);
      }

      if (name === 'dialCode' && value.dialCode) {
        onCountryChange(value.dialCode);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const countryOptions = useMemo(() => {
    return countries.map(country => ({
      value: country.name,
      label: `${country.name} (${country.dialCode})`,
    }));
  }, [countries]);

  return (
    <>
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <Input
            placeholder="phone"
            {...field}
            addonBefore={
              <Controller
                name="dialCode"
                control={control}
                render={({ field }) => (
                  <Select
                    showSearch
                    placeholder="Select a country"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countryOptions}
                    {...field}
                  />
                )}
              />
            }
          />
        )}
      />

      <Typography.Text type="danger">
        {dirtyFields.phone && errors.phone?.message}
      </Typography.Text>
    </>
  );
}
