export const routeConfig = {
  download: {
    path: 'download',
    children: {
      businessType: {
        path: 'business-type',
      },
      products: {
        path: 'products',
      },
      basicInfo: {
        path: 'basic-info',
      },
      verify: {
        path: 'verify',
      },
      downloadProducts: {
        path: 'download-products',
      },
    },
  },
};
