import { Checkbox, Col, Input, Row, Typography, Button } from 'antd';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from './basic-info.screens.module.css';
import { LeadModel } from '../../types';
import { useLead } from '../../services/leads.context';
import { useRouteChange } from '../../hooks';
import { useCreateLead } from '../../services/leads.api';
import { ProductType } from '../../config';
import { routeConfig } from '../../routes/config';
import { useVerifyEmail } from '../../services/verify.api';

const schema = yup.object({
  fullName: yup.string().required('Please enter your full name'),
  email: yup.string().email().required('Please enter your email'),
  businessName: yup.string().required('Please enter your business name'),
  prevPOS: yup.string(),
  updateOn: yup.boolean(),
  needCardProcessing: yup.boolean(),
});

export default function BasicInfo() {
  const { lead } = useLead();
  const verifyEmail = useVerifyEmail();
  const { navigate } = useRouteChange();
  const createLead = useCreateLead();

  const {
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: '',
      email: '',
      businessName: '',
      prevPOS: '',
      updateOn: true,
      needCardProcessing: false,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LeadModel> = data => {
    const createLeadRequest: LeadModel = {
      ...lead,
      ...data,
      type: 'DOWNLOAD',
      step: 3,
    };

    createLead.mutate(
      { data: createLeadRequest },
      {
        onSuccess: data => {
          if (data.product === ProductType.FLOREANT) {
            navigate(routeConfig.download.children.downloadProducts.path);
          } else {
            navigate(routeConfig.download.children.verify.path);
          }
        },
      },
    );
  };

  const handleEmailOnBlur = (value: string) => {
    if (!errors.email) {
      verifyEmail.mutate(
        {
          email: value,
        },
        {
          onSuccess: data => {
            if (data.emailRejected) {
              setError('email', {
                type: 'validate',
                message: 'Email is not valid, please try again',
              });
            }
          },
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => <Input placeholder="Full Name" {...field} />}
          />
          <Typography.Text type="danger">
            {errors.fullName?.message}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onBlur, ...rest } }) => (
              <Input
                type="email"
                onBlur={e => handleEmailOnBlur(e.target.value)}
                placeholder="Email"
                {...rest}
              />
            )}
          />
          <Typography.Text type="danger">
            {errors.email?.message}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Controller
            name="businessName"
            control={control}
            render={({ field }) => (
              <Input placeholder="Business Name" {...field} />
            )}
          />
          <Typography.Text type="danger">
            {errors.businessName?.message}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Controller
            name="prevPOS"
            control={control}
            render={({ field }) => (
              <Input placeholder="Previous POS (optional)" {...field} />
            )}
          />
        </Col>
        <Col span={24}>
          <Controller
            name="updateOn"
            control={control}
            render={({ field }) => (
              <Checkbox checked={field.value} {...field}>
                Like to learn more and get updates.
              </Checkbox>
            )}
          />
        </Col>
        <Col span={24}>
          <Controller
            name="needCardProcessing"
            control={control}
            render={({ field }) => (
              <Checkbox checked={field.value} {...field}>
                I need Integrated Card Processing
              </Checkbox>
            )}
          />
        </Col>
      </Row>

      <div className={styles.navigation}>
        <Button
          disabled={verifyEmail.isLoading}
          htmlType="submit"
          type="primary"
          loading={createLead.isLoading}
        >
          Next
        </Button>
      </div>
    </form>
  );
}
