import { Button } from 'antd';
import styles from './product.module.css';
import RightIcon from '../../assets/images/right.svg';
import { Product } from '../../types';

type Props = {
  product: Product;
  onSelect: (id: Product['id']) => void;
};

export default function ProductItem({ product, onSelect }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{product.title}</h1>
        <p className={styles.description}>{product.content}</p>
        <Button type="primary" onClick={() => onSelect(product.id)}>
          Select
        </Button>
      </div>

      <div className={styles.content}>
        <ul className={styles.list}>
          {product.features?.map((feature, index) => (
            <li key={index} className={styles.listItem}>
              <img className={styles.icon} src={RightIcon} alt="" />
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
