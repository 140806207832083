import { Button, Col, Input, Row, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { Phone } from '../../components';
import { countries } from '../../config/countries';
import styles from './verify.screen.module.css';
import { useSendPin, useVerifyPin } from '../../services/verify.api';
import { useUpdateLead } from '../../services/leads.api';
import { useInterval } from '../../hooks';
import { useLead } from '../../services/leads.context';
import { useNavigate } from 'react-router-dom';
import { routeConfig } from '../../routes/config';

export default function Verify() {
  const navigate = useNavigate();
  const { lead } = useLead();
  const sendPin = useSendPin();
  const updateLead = useUpdateLead();
  const verifyPin = useVerifyPin();
  const [phone, setPhone] = useState<string>();
  const [countryName, setCountryName] = useState<string>();
  const [disableCodeBtn, setDisableCodeBtn] = useState(false);
  const [verifyCode, setVerifyCode] = useState<string>();
  const [timer, setTimer] = useState(60);

  useInterval(
    () => {
      if (timer === 0) {
        setDisableCodeBtn(false);
        setTimer(60);
      } else {
        setTimer(timer - 1);
      }
    },
    disableCodeBtn ? 1000 : null,
  );

  const usaCountryName = useMemo(() => {
    const usa = countries.find(country => country.ISOCode === 'US');
    const defaultCountryName = usa?.name ?? '';
    setCountryName(defaultCountryName);

    return defaultCountryName;
  }, [countries]);

  const handleSendCode = () => {
    if (!phone || !countryName) {
      return;
    }

    updateLead.mutate(
      {
        phone,
        countryName,
      },
      {
        onSuccess: () => {
          sendPin.mutate(
            {
              phone,
              countryName,
            },
            {
              onSuccess: () => {
                setDisableCodeBtn(true);
              },
            },
          );
        },
      },
    );
  };

  const handleVerifyPhone = () => {
    const { email, phone } = lead;
    if (countryName && email && phone && verifyCode) {
      verifyPin.mutate(
        {
          countryName: countryName,
          email: email,
          phone: phone,
          code: verifyCode,
        },
        {
          onSuccess: data => {
            updateLead.mutate({
              status: 'byPhone',
              pin: data.pin,
            });

            navigate(`/${routeConfig.download.children.downloadProducts.path}`);
          },
        },
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <Row gutter={[6, 64]}>
        <Col span={18}>
          <Phone
            defaultCountryName={usaCountryName}
            onCountryChange={setCountryName}
            onPhoneChange={setPhone}
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={!countryName || !phone || disableCodeBtn}
            htmlType="submit"
            type="primary"
            onClick={handleSendCode}
            loading={sendPin.isLoading || updateLead.isLoading}
          >
            {sendPin.isSuccess || sendPin.isError ? 'Resend Code' : 'Send Code'}
          </Button>

          <Typography.Text type="secondary">
            {disableCodeBtn && `Resend again in ${timer} seconds`}
          </Typography.Text>
        </Col>

        {sendPin.isSuccess && (
          <>
            <Col span={6} offset={6}>
              <Input onChange={e => setVerifyCode(e.target.value)} />
            </Col>
            <Col span={6}>
              <Button
                htmlType="submit"
                disabled={!verifyCode}
                type="primary"
                onClick={handleVerifyPhone}
                loading={verifyPin.isLoading}
              >
                Verify Code
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}
