import { useMutation } from 'react-query';
import { useAxios } from '../hooks';
import { ApiResponse } from '../types';

type SendPinRequest = {
  countryName: string;
  phone: string;
};

type SendVerifyPinRequest = {
  countryName: string;
  phone: string;
  email: string;
  code: string;
};

type SendVerifyEmailRequest = {
  email: string;
};

type SendVerifyEmailResponse = {
  emailRejected: boolean;
  emailVerified: boolean;
};

type SendVerifyPinResponse = {
  pin: number;
};

export const useSendPin = () => {
  const axios = useAxios();

  return useMutation(async (data: SendPinRequest) => {
    const response = await axios.post<ApiResponse<string>>(
      `/verify/send-code-phone`,
      data,
    );

    return response.data.data;
  });
};

export const useVerifyPin = () => {
  const axios = useAxios();

  return useMutation(async (data: SendVerifyPinRequest) => {
    const response = await axios.post<ApiResponse<SendVerifyPinResponse>>(
      `/verify/verify-phone`,
      data,
    );

    return response.data.data;
  });
};

export const useVerifyEmail = () => {
  const axios = useAxios();

  return useMutation(async (data: SendVerifyEmailRequest) => {
    const response = await axios.post<ApiResponse<SendVerifyEmailResponse>>(
      `/verify/verify-email`,
      data,
    );

    return response.data.data;
  });
};
