export const countries = [
  {
    id: 1,
    name: 'Israel',
    dialCode: '+972',
    ISOCode: 'IL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 2,
    name: 'Afghanistan',
    dialCode: '+93',
    ISOCode: 'AF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 3,
    name: 'Albania',
    dialCode: '+355',
    ISOCode: 'AL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 4,
    name: 'Algeria',
    dialCode: '+213',
    ISOCode: 'DZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 5,
    name: 'AmericanSamoa',
    dialCode: '+1 684',
    ISOCode: 'AS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 6,
    name: 'Andorra',
    dialCode: '+376',
    ISOCode: 'AD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 7,
    name: 'Angola',
    dialCode: '+244',
    ISOCode: 'AO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 8,
    name: 'Anguilla',
    dialCode: '+1 264',
    ISOCode: 'AI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    ISOCode: 'AG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 10,
    name: 'Argentina',
    dialCode: '+54',
    ISOCode: 'AR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 11,
    name: 'Armenia',
    dialCode: '+374',
    ISOCode: 'AM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 12,
    name: 'Aruba',
    dialCode: '+297',
    ISOCode: 'AW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 13,
    name: 'Australia',
    dialCode: '+61',
    ISOCode: 'AU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 14,
    name: 'Austria',
    dialCode: '+43',
    ISOCode: 'AT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 15,
    name: 'Azerbaijan',
    dialCode: '+994',
    ISOCode: 'AZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 16,
    name: 'Bahamas',
    dialCode: '+1 242',
    ISOCode: 'BS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 17,
    name: 'Bahrain',
    dialCode: '+973',
    ISOCode: 'BH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 18,
    name: 'Bangladesh',
    dialCode: '+880',
    ISOCode: 'BD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 19,
    name: 'Barbados',
    dialCode: '+1 246',
    ISOCode: 'BB',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 20,
    name: 'Belarus',
    dialCode: '+375',
    ISOCode: 'BY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 21,
    name: 'Belgium',
    dialCode: '+32',
    ISOCode: 'BE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 22,
    name: 'Belize',
    dialCode: '+501',
    ISOCode: 'BZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 23,
    name: 'Benin',
    dialCode: '+229',
    ISOCode: 'BJ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 24,
    name: 'Bermuda',
    dialCode: '+1 441',
    ISOCode: 'BM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 25,
    name: 'Bhutan',
    dialCode: '+975',
    ISOCode: 'BT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 26,
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    ISOCode: 'BA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 27,
    name: 'Botswana',
    dialCode: '+267',
    ISOCode: 'BW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 28,
    name: 'Brazil',
    dialCode: '+55',
    ISOCode: 'BR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 29,
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    ISOCode: 'IO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 30,
    name: 'Bulgaria',
    dialCode: '+359',
    ISOCode: 'BG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 31,
    name: 'Burkina Faso',
    dialCode: '+226',
    ISOCode: 'BF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 32,
    name: 'Burundi',
    dialCode: '+257',
    ISOCode: 'BI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 33,
    name: 'Cambodia',
    dialCode: '+855',
    ISOCode: 'KH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 34,
    name: 'Cameroon',
    dialCode: '+237',
    ISOCode: 'CM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 35,
    name: 'Canada',
    dialCode: '+1',
    ISOCode: 'CA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 36,
    name: 'Cape Verde',
    dialCode: '+238',
    ISOCode: 'CV',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 37,
    name: 'Cayman Islands',
    dialCode: '+ 345',
    ISOCode: 'KY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 38,
    name: 'Central African Republic',
    dialCode: '+236',
    ISOCode: 'CF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 39,
    name: 'Chad',
    dialCode: '+235',
    ISOCode: 'TD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 40,
    name: 'Chile',
    dialCode: '+56',
    ISOCode: 'CL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 41,
    name: 'China',
    dialCode: '+86',
    ISOCode: 'CN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 42,
    name: 'Christmas Island',
    dialCode: '+61',
    ISOCode: 'CX',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 43,
    name: 'Colombia',
    dialCode: '+57',
    ISOCode: 'CO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 44,
    name: 'Comoros',
    dialCode: '+269',
    ISOCode: 'KM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 45,
    name: 'Congo',
    dialCode: '+242',
    ISOCode: 'CG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 46,
    name: 'Cook Islands',
    dialCode: '+682',
    ISOCode: 'CK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 47,
    name: 'Costa Rica',
    dialCode: '+506',
    ISOCode: 'CR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 48,
    name: 'Croatia',
    dialCode: '+385',
    ISOCode: 'HR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 49,
    name: 'Cuba',
    dialCode: '+53',
    ISOCode: 'CU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 50,
    name: 'Cyprus',
    dialCode: '+537',
    ISOCode: 'CY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 51,
    name: 'Czech Republic',
    dialCode: '+420',
    ISOCode: 'CZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 52,
    name: 'Denmark',
    dialCode: '+45',
    ISOCode: 'DK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 53,
    name: 'Djibouti',
    dialCode: '+253',
    ISOCode: 'DJ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 54,
    name: 'Dominica',
    dialCode: '+1 767',
    ISOCode: 'DM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 55,
    name: 'Dominican Republic',
    dialCode: '+1 849',
    ISOCode: 'DO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 56,
    name: 'Ecuador',
    dialCode: '+593',
    ISOCode: 'EC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 57,
    name: 'Egypt',
    dialCode: '+20',
    ISOCode: 'EG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 58,
    name: 'El Salvador',
    dialCode: '+503',
    ISOCode: 'SV',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 59,
    name: 'Equatorial Guinea',
    dialCode: '+240',
    ISOCode: 'GQ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 60,
    name: 'Eritrea',
    dialCode: '+291',
    ISOCode: 'ER',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 61,
    name: 'Estonia',
    dialCode: '+372',
    ISOCode: 'EE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 62,
    name: 'Ethiopia',
    dialCode: '+251',
    ISOCode: 'ET',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 63,
    name: 'Faroe Islands',
    dialCode: '+298',
    ISOCode: 'FO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 64,
    name: 'Fiji',
    dialCode: '+679',
    ISOCode: 'FJ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 65,
    name: 'Finland',
    dialCode: '+358',
    ISOCode: 'FI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 66,
    name: 'France',
    dialCode: '+33',
    ISOCode: 'FR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 67,
    name: 'French Guiana',
    dialCode: '+594',
    ISOCode: 'GF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 68,
    name: 'French Polynesia',
    dialCode: '+689',
    ISOCode: 'PF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 69,
    name: 'Gabon',
    dialCode: '+241',
    ISOCode: 'GA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 70,
    name: 'Gambia',
    dialCode: '+220',
    ISOCode: 'GM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 71,
    name: 'Georgia',
    dialCode: '+995',
    ISOCode: 'GE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 72,
    name: 'Germany',
    dialCode: '+49',
    ISOCode: 'DE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 73,
    name: 'Ghana',
    dialCode: '+233',
    ISOCode: 'GH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 74,
    name: 'Gibraltar',
    dialCode: '+350',
    ISOCode: 'GI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 75,
    name: 'Greece',
    dialCode: '+30',
    ISOCode: 'GR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 76,
    name: 'Greenland',
    dialCode: '+299',
    ISOCode: 'GL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 77,
    name: 'Grenada',
    dialCode: '+1 473',
    ISOCode: 'GD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 78,
    name: 'Guadeloupe',
    dialCode: '+590',
    ISOCode: 'GP',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 79,
    name: 'Guam',
    dialCode: '+1 671',
    ISOCode: 'GU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 80,
    name: 'Guatemala',
    dialCode: '+502',
    ISOCode: 'GT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 81,
    name: 'Guinea',
    dialCode: '+224',
    ISOCode: 'GN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 82,
    name: 'Guinea-Bissau',
    dialCode: '+245',
    ISOCode: 'GW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 83,
    name: 'Guyana',
    dialCode: '+595',
    ISOCode: 'GY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 84,
    name: 'Haiti',
    dialCode: '+509',
    ISOCode: 'HT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 85,
    name: 'Honduras',
    dialCode: '+504',
    ISOCode: 'HN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 86,
    name: 'Hungary',
    dialCode: '+36',
    ISOCode: 'HU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 87,
    name: 'Iceland',
    dialCode: '+354',
    ISOCode: 'IS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 88,
    name: 'India',
    dialCode: '+91',
    ISOCode: 'IN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 89,
    name: 'Indonesia',
    dialCode: '+62',
    ISOCode: 'ID',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 90,
    name: 'Iraq',
    dialCode: '+964',
    ISOCode: 'IQ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 91,
    name: 'Ireland',
    dialCode: '+353',
    ISOCode: 'IE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 92,
    name: 'Italy',
    dialCode: '+39',
    ISOCode: 'IT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 93,
    name: 'Jamaica',
    dialCode: '+1 876',
    ISOCode: 'JM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 94,
    name: 'Japan',
    dialCode: '+81',
    ISOCode: 'JP',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 95,
    name: 'Jordan',
    dialCode: '+962',
    ISOCode: 'JO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 96,
    name: 'Kazakhstan',
    dialCode: '+7 7',
    ISOCode: 'KZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 97,
    name: 'Kenya',
    dialCode: '+254',
    ISOCode: 'KE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 98,
    name: 'Kiribati',
    dialCode: '+686',
    ISOCode: 'KI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 99,
    name: 'Kuwait',
    dialCode: '+965',
    ISOCode: 'KW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 100,
    name: 'Kyrgyzstan',
    dialCode: '+996',
    ISOCode: 'KG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 101,
    name: 'Latvia',
    dialCode: '+371',
    ISOCode: 'LV',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 102,
    name: 'Lebanon',
    dialCode: '+961',
    ISOCode: 'LB',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 103,
    name: 'Lesotho',
    dialCode: '+266',
    ISOCode: 'LS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 104,
    name: 'Liberia',
    dialCode: '+231',
    ISOCode: 'LR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 105,
    name: 'Liechtenstein',
    dialCode: '+423',
    ISOCode: 'LI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 106,
    name: 'Lithuania',
    dialCode: '+370',
    ISOCode: 'LT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 107,
    name: 'Luxembourg',
    dialCode: '+352',
    ISOCode: 'LU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 108,
    name: 'Madagascar',
    dialCode: '+261',
    ISOCode: 'MG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 109,
    name: 'Malawi',
    dialCode: '+265',
    ISOCode: 'MW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 110,
    name: 'Malaysia',
    dialCode: '+60',
    ISOCode: 'MY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 111,
    name: 'Maldives',
    dialCode: '+960',
    ISOCode: 'MV',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 112,
    name: 'Mali',
    dialCode: '+223',
    ISOCode: 'ML',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 113,
    name: 'Malta',
    dialCode: '+356',
    ISOCode: 'MT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 114,
    name: 'Marshall Islands',
    dialCode: '+692',
    ISOCode: 'MH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 115,
    name: 'Martinique',
    dialCode: '+596',
    ISOCode: 'MQ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 116,
    name: 'Mauritania',
    dialCode: '+222',
    ISOCode: 'MR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 117,
    name: 'Mauritius',
    dialCode: '+230',
    ISOCode: 'MU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 118,
    name: 'Mayotte',
    dialCode: '+262',
    ISOCode: 'YT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 119,
    name: 'Mexico',
    dialCode: '+52',
    ISOCode: 'MX',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 120,
    name: 'Monaco',
    dialCode: '+377',
    ISOCode: 'MC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 121,
    name: 'Mongolia',
    dialCode: '+976',
    ISOCode: 'MN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 122,
    name: 'Montenegro',
    dialCode: '+382',
    ISOCode: 'ME',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 123,
    name: 'Montserrat',
    dialCode: '+1664',
    ISOCode: 'MS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 124,
    name: 'Morocco',
    dialCode: '+212',
    ISOCode: 'MA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 125,
    name: 'Myanmar',
    dialCode: '+95',
    ISOCode: 'MM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 126,
    name: 'Namibia',
    dialCode: '+264',
    ISOCode: 'NA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 127,
    name: 'Nauru',
    dialCode: '+674',
    ISOCode: 'NR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 128,
    name: 'Nepal',
    dialCode: '+977',
    ISOCode: 'NP',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 129,
    name: 'Netherlands',
    dialCode: '+31',
    ISOCode: 'NL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 130,
    name: 'Netherlands Antilles',
    dialCode: '+599',
    ISOCode: 'AN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 131,
    name: 'New Caledonia',
    dialCode: '+687',
    ISOCode: 'NC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 132,
    name: 'New Zealand',
    dialCode: '+64',
    ISOCode: 'NZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 133,
    name: 'Nicaragua',
    dialCode: '+505',
    ISOCode: 'NI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 134,
    name: 'Niger',
    dialCode: '+227',
    ISOCode: 'NE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 135,
    name: 'Nigeria',
    dialCode: '+234',
    ISOCode: 'NG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 136,
    name: 'Niue',
    dialCode: '+683',
    ISOCode: 'NU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 137,
    name: 'Norfolk Island',
    dialCode: '+672',
    ISOCode: 'NF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 138,
    name: 'Northern Mariana Islands',
    dialCode: '+1 670',
    ISOCode: 'MP',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 139,
    name: 'Norway',
    dialCode: '+47',
    ISOCode: 'NO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 140,
    name: 'Oman',
    dialCode: '+968',
    ISOCode: 'OM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 141,
    name: 'Pakistan',
    dialCode: '+92',
    ISOCode: 'PK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 142,
    name: 'Palau',
    dialCode: '+680',
    ISOCode: 'PW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 143,
    name: 'Panama',
    dialCode: '+507',
    ISOCode: 'PA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 144,
    name: 'Papua New Guinea',
    dialCode: '+675',
    ISOCode: 'PG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 145,
    name: 'Paraguay',
    dialCode: '+595',
    ISOCode: 'PY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 146,
    name: 'Peru',
    dialCode: '+51',
    ISOCode: 'PE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 147,
    name: 'Philippines',
    dialCode: '+63',
    ISOCode: 'PH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 148,
    name: 'Poland',
    dialCode: '+48',
    ISOCode: 'PL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 149,
    name: 'Portugal',
    dialCode: '+351',
    ISOCode: 'PT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 150,
    name: 'Puerto Rico',
    dialCode: '+1 939',
    ISOCode: 'PR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 151,
    name: 'Qatar',
    dialCode: '+974',
    ISOCode: 'QA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 152,
    name: 'Romania',
    dialCode: '+40',
    ISOCode: 'RO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 153,
    name: 'Rwanda',
    dialCode: '+250',
    ISOCode: 'RW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 154,
    name: 'Samoa',
    dialCode: '+685',
    ISOCode: 'WS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 155,
    name: 'San Marino',
    dialCode: '+378',
    ISOCode: 'SM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 156,
    name: 'Saudi Arabia',
    dialCode: '+966',
    ISOCode: 'SA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 157,
    name: 'Senegal',
    dialCode: '+221',
    ISOCode: 'SN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 158,
    name: 'Serbia',
    dialCode: '+381',
    ISOCode: 'RS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:23.000Z',
    updatedAt: '2020-03-12T05:21:23.000Z',
  },
  {
    id: 159,
    name: 'Seychelles',
    dialCode: '+248',
    ISOCode: 'SC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 160,
    name: 'Sierra Leone',
    dialCode: '+232',
    ISOCode: 'SL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 161,
    name: 'Singapore',
    dialCode: '+65',
    ISOCode: 'SG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 162,
    name: 'Slovakia',
    dialCode: '+421',
    ISOCode: 'SK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 163,
    name: 'Slovenia',
    dialCode: '+386',
    ISOCode: 'SI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 164,
    name: 'Solomon Islands',
    dialCode: '+677',
    ISOCode: 'SB',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 165,
    name: 'South Africa',
    dialCode: '+27',
    ISOCode: 'ZA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 166,
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    ISOCode: 'GS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 167,
    name: 'Spain',
    dialCode: '+34',
    ISOCode: 'ES',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 168,
    name: 'Sri Lanka',
    dialCode: '+94',
    ISOCode: 'LK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 169,
    name: 'Sudan',
    dialCode: '+249',
    ISOCode: 'SD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 170,
    name: 'Suriname',
    dialCode: '+597',
    ISOCode: 'SR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 171,
    name: 'Swaziland',
    dialCode: '+268',
    ISOCode: 'SZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 172,
    name: 'Sweden',
    dialCode: '+46',
    ISOCode: 'SE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 173,
    name: 'Switzerland',
    dialCode: '+41',
    ISOCode: 'CH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 174,
    name: 'Tajikistan',
    dialCode: '+992',
    ISOCode: 'TJ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 175,
    name: 'Thailand',
    dialCode: '+66',
    ISOCode: 'TH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 176,
    name: 'Togo',
    dialCode: '+228',
    ISOCode: 'TG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 177,
    name: 'Tokelau',
    dialCode: '+690',
    ISOCode: 'TK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 178,
    name: 'Tonga',
    dialCode: '+676',
    ISOCode: 'TO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 179,
    name: 'Trinidad and Tobago',
    dialCode: '+1 868',
    ISOCode: 'TT',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 180,
    name: 'Tunisia',
    dialCode: '+216',
    ISOCode: 'TN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 181,
    name: 'Turkey',
    dialCode: '+90',
    ISOCode: 'TR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 182,
    name: 'Turkmenistan',
    dialCode: '+993',
    ISOCode: 'TM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 183,
    name: 'Turks and Caicos Islands',
    dialCode: '+1 649',
    ISOCode: 'TC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 184,
    name: 'Tuvalu',
    dialCode: '+688',
    ISOCode: 'TV',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 185,
    name: 'Uganda',
    dialCode: '+256',
    ISOCode: 'UG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 186,
    name: 'Ukraine',
    dialCode: '+380',
    ISOCode: 'UA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 187,
    name: 'United Arab Emirates',
    dialCode: '+971',
    ISOCode: 'AE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 188,
    name: 'United Kingdom',
    dialCode: '+44',
    ISOCode: 'GB',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 189,
    name: 'United States',
    dialCode: '+1',
    ISOCode: 'US',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 190,
    name: 'Uruguay',
    dialCode: '+598',
    ISOCode: 'UY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 191,
    name: 'Uzbekistan',
    dialCode: '+998',
    ISOCode: 'UZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 192,
    name: 'Vanuatu',
    dialCode: '+678',
    ISOCode: 'VU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 193,
    name: 'Wallis and Futuna',
    dialCode: '+681',
    ISOCode: 'WF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 194,
    name: 'Yemen',
    dialCode: '+967',
    ISOCode: 'YE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 195,
    name: 'Zambia',
    dialCode: '+260',
    ISOCode: 'ZM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 196,
    name: 'Zimbabwe',
    dialCode: '+263',
    ISOCode: 'ZW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 197,
    name: 'land Islands',
    dialCode: '',
    ISOCode: 'AX',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 198,
    name: 'Antarctica',
    dialCode: null,
    ISOCode: 'AQ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 199,
    name: 'Bolivia, Plurinational State of',
    dialCode: '+591',
    ISOCode: 'BO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 200,
    name: 'Brunei Darussalam',
    dialCode: '+673',
    ISOCode: 'BN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 201,
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    ISOCode: 'CC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 202,
    name: 'Congo, The Democratic Republic of the',
    dialCode: '+243',
    ISOCode: 'CD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 203,
    name: "Cote d'Ivoire",
    dialCode: '+225',
    ISOCode: 'CI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 204,
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    ISOCode: 'FK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 205,
    name: 'Guernsey',
    dialCode: '+44',
    ISOCode: 'GG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 206,
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    ISOCode: 'VA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 207,
    name: 'Hong Kong',
    dialCode: '+852',
    ISOCode: 'HK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 208,
    name: 'Iran, Islamic Republic of',
    dialCode: '+98',
    ISOCode: 'IR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 209,
    name: 'Isle of Man',
    dialCode: '+44',
    ISOCode: 'IM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 210,
    name: 'Jersey',
    dialCode: '+44',
    ISOCode: 'JE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 211,
    name: "Korea, Democratic People's Republic of",
    dialCode: '+850',
    ISOCode: 'KP',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 212,
    name: 'Korea, Republic of',
    dialCode: '+82',
    ISOCode: 'KR',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 213,
    name: "Lao People's Democratic Republic",
    dialCode: '+856',
    ISOCode: 'LA',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 214,
    name: 'Libyan Arab Jamahiriya',
    dialCode: '+218',
    ISOCode: 'LY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 215,
    name: 'Macao',
    dialCode: '+853',
    ISOCode: 'MO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 216,
    name: 'Macedonia, The Former Yugoslav Republic of',
    dialCode: '+389',
    ISOCode: 'MK',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 217,
    name: 'Micronesia, Federated States of',
    dialCode: '+691',
    ISOCode: 'FM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 218,
    name: 'Moldova, Republic of',
    dialCode: '+373',
    ISOCode: 'MD',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 219,
    name: 'Mozambique',
    dialCode: '+258',
    ISOCode: 'MZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 220,
    name: 'Palestinian Territory, Occupied',
    dialCode: '+970',
    ISOCode: 'PS',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 221,
    name: 'Pitcairn',
    dialCode: '+872',
    ISOCode: 'PN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 222,
    name: 'Réunion',
    dialCode: '+262',
    ISOCode: 'RE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 223,
    name: 'Russia',
    dialCode: '+7',
    ISOCode: 'RU',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 224,
    name: 'Saint Barthélemy',
    dialCode: '+590',
    ISOCode: 'BL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 225,
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    ISOCode: 'SH',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 226,
    name: 'Saint Kitts and Nevis',
    dialCode: '+1 869',
    ISOCode: 'KN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 227,
    name: 'Saint Lucia',
    dialCode: '+1 758',
    ISOCode: 'LC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 228,
    name: 'Saint Martin',
    dialCode: '+590',
    ISOCode: 'MF',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 229,
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    ISOCode: 'PM',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 230,
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1 784',
    ISOCode: 'VC',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 231,
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    ISOCode: 'ST',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 232,
    name: 'Somalia',
    dialCode: '+252',
    ISOCode: 'SO',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 233,
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    ISOCode: 'SJ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 234,
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    ISOCode: 'SY',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 235,
    name: 'Taiwan, Province of China',
    dialCode: '+886',
    ISOCode: 'TW',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 236,
    name: 'Tanzania, United Republic of',
    dialCode: '+255',
    ISOCode: 'TZ',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 237,
    name: 'Timor-Leste',
    dialCode: '+670',
    ISOCode: 'TL',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 238,
    name: 'Venezuela, Bolivarian Republic of',
    dialCode: '+58',
    ISOCode: 'VE',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 239,
    name: 'Viet Nam',
    dialCode: '+84',
    ISOCode: 'VN',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 240,
    name: 'Virgin Islands, British',
    dialCode: '+1 284',
    ISOCode: 'VG',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
  {
    id: 241,
    name: 'Virgin Islands, U.S.',
    dialCode: '+1 340',
    ISOCode: 'VI',
    isAllowedPhoneVerification: false,
    createdAt: '2020-03-12T05:21:24.000Z',
    updatedAt: '2020-03-12T05:21:24.000Z',
  },
];
