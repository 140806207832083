import { Progress } from 'antd';
import { useLead } from '../../services/leads.context';

export default function ProgressBar() {
  const { lead } = useLead();

  const leadProgress = (lead.step ?? 0) * 25;

  return <Progress percent={leadProgress} />;
}
